import "./scss/site.scss";

import "bootstrap"; // TODO import only what is needed


const links = document.getElementsByClassName('nav-link')
const link = [...links].find(link => link.href === window.location.href)
if (link) {
  link.classList.add('active')
}
